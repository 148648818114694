import axios from 'axios'
import {ProductModel} from '../../../../models/ems/ProductModel'
import {FilterModel} from '../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import { BookingModelCreateParams } from '../booking-wizard/BookingWizard'
import { EventModel } from '../../../../models/ems/EventModel'
import { LocationModel } from '../../../../models/acs/LocationModel'
import { ActivityModel } from '../../../../models/ems/ActivityModel'

export const CreateRetailBooking = (data: BookingModelCreateParams) => {
  axios.post('bookings/hub', data)
}

export const GetProductList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductModel>>(`product/customer/list`, filter)

export const GetProductByCode = (code: string) => axios.get<ProductModel>(`/product/customer/${code}`)
export const GetLocationsByProductCode = (productCode: string) =>
axios.get<LocationModel[]>(`/product/hub/${productCode}/location`)

export const GetEventList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<EventModel>>(`/event/app-list`, filter)
  
export const GetActivityList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ActivityModel>>(`/event/activity/list`, filter)

export const GetLocationByCode = (locationCode: string) =>
axios.get<LocationModel>(`/location/${locationCode}`)

export const GetAvailableSeatMaps = (
  locationCode: string,
  productCode: string,
  eventCode: string,
  // customerCode: string
  startedAt: string,
  endedAt: string
) =>
  axios.post(`/location/hub/${locationCode}/seatmap/availability`, {
    productCode,
    eventCode,
    // customerCode,
    startedAt,
    endedAt,
  })

