import {useMemo} from 'react'
import {ActivityModel} from '../../../../models/ems/ActivityModel'
import {ImageInputValue} from '../../../../components/inputs/FileInput/ImageInputValue'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import style from '../../pages/EventListStyle.module.scss'

interface ActivityCardProps {
  data: ActivityModel
  onClick: () => void
  config?: Record<string, string> | null
}

export const ActivityCard: React.FC<ActivityCardProps> = ({data, onClick, config}) => {
  const image = useMemo(() => {
    if (data.emsFile && config) {
      return new ImageInputValue(config.STATIC_PUBLIC_FILE_URL, data.emsFile).url
    } else {
      return null
    }
  }, [config, data.emsFile])

  return (
    <>
      <div className='card bg-dark text-white'
      style={{width: '350px', height: '350px', marginBottom: '40px', marginRight: '40px'}}
      onClick={onClick}>
        <img src={image ? image : toAbsoluteUrl('/default_product_img.png')} alt='img'/>
        <div className={clsx('align-items-end', style.overlay)}>
            <h5 className={style.cardTitle}>{data.name}</h5>
            <p className={style.cardDescription}>{data.description}</p>
        </div>
      </div>
    </>
  )
}
