import axios from 'axios'
import { AuthModel } from '../../../../models/customer-portal/AuthModel'

const PORTAL_AUTH_BASE_PATH = '/portal-auth'


export interface EmailTypeParams {
  email: string
  type: string
}

export interface CustomerCodeParams {
  customerCode: string
}

// interface ReassignSeatParams {
//   reason: string
//   locationCode: string
//   seatRow: string
//   seatNo: number
// }

// LOGIN
export const AuthLogin = (username: string, password: string) =>
  axios.post<AuthModel>(`${PORTAL_AUTH_BASE_PATH}/login-customer`, {username, password})
export const RequestForgottenPassword = (email: string) =>
  axios.post<AuthModel>(`${PORTAL_AUTH_BASE_PATH}/forgot-customer-password`, {email})

export const VerifyCustomerBookingAuthToken = () =>
  axios.get<AuthModel>(`${PORTAL_AUTH_BASE_PATH}/verify-customer-token`)

export const ResetPassword = (password: string) =>
  axios.post(`${PORTAL_AUTH_BASE_PATH}/reset/customer`, {password})
export const validateHash = (hash: string) =>
  axios.post(`${PORTAL_AUTH_BASE_PATH}/vaslidate-hash`, {hash})

export const validateMfa = (mfa: string, authTokenn: string) =>
  axios.post(
    `${PORTAL_AUTH_BASE_PATH}/validate-customer-mfa`,
    {mfa},
    {
      headers: {
        Authorization: `Bearer ${authTokenn}`,
      },
    }
  )

export const loginWithHash = (username: string, password: string, hash: string) =>
  axios.post(`${PORTAL_AUTH_BASE_PATH}/login-hash`, {username, password, hash})

