import {SetStateAction, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {LoginHeader} from '../../LoginHeader'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {useDispatch} from 'react-redux'
import {DigitInput} from '../../../../../components/inputs/DigitInput'
import clsx from 'clsx'
import {AxiosError} from 'axios'
import {AuthLogin, validateHash} from '../../redux/CustomerPortalCRUD'
import * as _redux from '../../redux/CustomerPortalRedux'
import {LoginFields} from '../../forms/LoginFields'
import {QrCode} from '../../../../../components/utils/QrCode'
import {FormikContextType, useFormik} from 'formik'
import * as Yup from 'yup'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {FormStep} from '../BookingWizard'
import {useFormikLogin} from '../../../hooks/useFormikLogin'
import {useStepperState} from '../../../pages/StepperStateContext'

export interface FormikProps {
  username: string
  password: string
  otpValue: string
}

export const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(10, 'Minimum 10 characters')
    .max(50, 'Maximum 50 characters')
    .required('Username is required'),
  password: Yup.string()
    .min(6, 'Minimum 6 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
})

export const loginInitialValues: FormikProps = {
  username: '',
  password: '',
  otpValue: '',
}

export interface BookingWizardCustomerInformationProps {
  clickedPrevious: boolean
  setLoginPrevious: (value: boolean) => void
  setCurrentStep: (value: SetStateAction<FormStep>) => void
}

export const BookingWizardCustomerInformation = ({
  clickedPrevious,
  setCurrentStep,
  setLoginPrevious,
}: BookingWizardCustomerInformationProps) => {
  const {
    loginSubmit,
    setLoginSubmit,
    clickedRegister,
    setClickedRegister,
    setRetailAuthToken,
  } = useStepperState()
  const otpInputRef = useRef<HTMLInputElement | null>(null)
  const [mfaLink, setMfaLink] = useState<string>()
  const [authToken, setAuthToken] = useState<string>()
  const [hash, setHash] = useState<string>()
  const match = useRouteMatch<{hash?: string}>()
  const history = useHistory()
  const {push} = useAlerts()
  const dispatch = useDispatch()

  const validateHashFromUrl = useCallback(
    async (hash: string) => {
      try {
        await validateHash(hash)
        push({
          message: `Please enter your username and password.`,
          timeout: 10000,
          variant: 'success',
        })
        setHash(hash)
      } catch (e) {
        push({
          message: `Invalid url. Please contact support.`,
          timeout: 10000,
          variant: 'danger',
        })
        history.replace('/auth/login')
      }
    },
    [history, push]
  )

  useEffect(() => {
    if (match.params.hash) {
      validateHashFromUrl(match.params.hash)
    }
  }, [match.params.hash, validateHashFromUrl])

  const formikSubmit = useFormikLogin({
    setMfaLink,
    setAuthToken,
    otpInputRef,
  })
  const formik: FormikContextType<FormikProps> = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus}) => {
    setStatus('')
      if (values.otpValue && authToken) {
        const isOtpValid = await formikSubmit.sendRequestByOTP(values.otpValue, formik, authToken)
      //  if(isOtpValid) {
      //   setCurrentStep(FormStep.PAYMENT)
      //  }
        if (isOtpValid.user.isFirstLogin ) {
          setCurrentStep(FormStep.FIRSTLOGIN)
        } else if (isOtpValid && isOtpValid.user.isFirstLogin === false){
          setCurrentStep(FormStep.PAYMENT)
        } else if (!isOtpValid){
          formik.setFieldValue('otpValue', '')
          formik.setStatus('Invalid OTP')
        }
      } else if (mfaLink) {
        setMfaLink(undefined)
        otpInputRef.current?.focus()
      } else {
        try {
          if (hash) {
            formikSubmit.sendRequestByHash(values.username, values.password, hash)
          } else {
            const {data, status} = await AuthLogin(values.username, values.password)
            if (status === 202) {
              setAuthToken(data.token)
              otpInputRef.current?.focus()
              setRetailAuthToken(true)
            } else {
              dispatch(_redux.actions.auth.login(data.token))
            }
          }
        } catch (e: any) {
          const responseError: AxiosError<unknown> = e

          if (responseError.response?.status) {
            if (responseError.response.status === 403) {
              setStatus('Please check your email to enable your 2FA authentication.')
            } else {
              setStatus('Invalid username or password.')
            }
          } else {
            setStatus('Something went wrong. Please try again later.')
          }
        }
      }
      setLoginSubmit(false)
    },
  })

  const handleFormKeyPress = (e: React.KeyboardEvent<HTMLFormElement>) => {
   if (e.key === 'Enter' && authToken && !mfaLink) {
      e.preventDefault()     
      formik.handleSubmit()
      setLoginSubmit(false)
    }
  }


  const handleOtpChange = useCallback(
    (value: string) => {
      formik.setFieldValue('otpValue', value)
    },
    [formik]
  )

  useOnChange(loginSubmit, () => {
    if (loginSubmit) {
      formik.handleSubmit()
      setLoginSubmit(false)
    }
  })

  useOnChange(clickedRegister, () => {
    if (clickedRegister) {
      setClickedRegister(false)
    }
  })

  useOnChange(clickedPrevious, () => {
    if (clickedPrevious && !authToken) {
      setLoginPrevious(false)
      setCurrentStep(FormStep.CALENDAR)
      handleBack()
    } else if (clickedPrevious) {
      setCurrentStep(FormStep.LOGIN)
      setLoginPrevious(false)
      handleBack()
    }
  })

  const loginFields = useMemo(() => {
    if (!authToken) {
      return <LoginFields formik={formik} />
    }
  }, [authToken, formik])


  const otpFields = useMemo(() => {
    const isShown = Boolean(authToken && !mfaLink)
    return (
      <div className={clsx('m-5', {'d-none': !isShown})}>
        <DigitInput
          ref={otpInputRef}
          className='mt-5'
          value={formik.values.otpValue}
          onChange={handleOtpChange}
          length={OTP_LENGTH}
        />
      </div>
    )
  }, [authToken, formik.values.otpValue, handleOtpChange, mfaLink])

  const mfaQrCode = useMemo(() => {
    if (mfaLink) {
      return (
        <div className='d-flex justify-content-center mb-5'>
          <div className='p-3 d-inline-block bg-white'>
            <a href={mfaLink}>
              <QrCode value={mfaLink} />
            </a>
          </div>
        </div>
      )
    }
  }, [mfaLink])

  const handleBack = useCallback(() => {
    formik.resetForm()
    setMfaLink(undefined)
    setAuthToken(undefined)
  }, [formik])
  return (
    <>
      <div
        className='bg-body rounded shadow-sm p-10 p-lg-15'
        style={{maxWidth: '57vw', margin: 'auto'}}
      >
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          onKeyPress={handleFormKeyPress}
          noValidate
          id='kt_login_signin_form'
        >
          <LoginHeader authToken={authToken} mfaLink={mfaLink} />

          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
          {loginFields}
          {otpFields}
          {mfaQrCode}
          {/* <div className='text-center'>
        <button
          type='submit'
          className='btn btn-lg btn-light-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {(authToken || mfaLink) && (
          <Button
            type='button'
            className='w-100'
            onClick={handleBack}
            disabled={formik.isSubmitting}
          >
            Back
          </Button>
        )}
      </div> */}
        </form>
      </div>
    </>
  )
}

const OTP_LENGTH = 6
