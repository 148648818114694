import {FormikContextType} from 'formik'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useLocalTableSearch} from '../../hooks/useLocalTableSearch'
import {useOnChange} from '../../hooks/useOnChange'
import {ColumnStyle} from '../../tables/constants/ColumnStyle'
import {TableColumnOptions} from '../../tables/TableColumn'
import {
  BookingFormValues,
  BookingWizardFinalSeatsStepFormValues,
} from '../../../models/booking-wizard/BookingWizard'

import {CustomersProductProps} from '../../../models/booking-wizard/BulkBookingWizard'
import {TicketModelFulfillBulkParams} from '../../../models/ems/TicketModel'
import {concat, map} from 'lodash'
import {
  getBulkSeatMapPayload,
  SeatMapSelectionModalFormValues,
} from '../../forms/SeatMapSelectionModalForm'
import {CustomersProductTableActions} from './CustomersProductTableActions'
import {useLoadingState} from '../../hooks/useLoadingState'
import {FilterTable} from '../../tables/FilterTable'
import {idExtractor} from '../../../utils/idExtractor'
import {BookingSeatMap} from '../../BookingWizard/component/bulkBooking/BookingSeatMap'
import {SeatMapValue} from '../../inputs/SeatMapInput/SeatMapValue'
import {useModalState} from '../../modals/useModalState'
import {MetronicIcon} from '../../inputs/MetronicIcon'
import {Badge} from '../../badge/Badge'
import {SeatTableColumn} from '../component/SeatTableColumn'
import {FinalStepCustomerHeader} from '../component/FinalStepCustomerHeader'
import moment from 'moment'
import {DateUtil} from '../../../utils/DateUtil'
import {DateRange} from '../../../utils/DateRange'
import {NonSeatedTimeslotDateModalForm} from '../BookingWizardTables/modals/NonSeatedTimeSlotDateModalForm'
import {useAlerts} from '../../alerts/useAlerts'
export interface BookingWizardSharedFinalizeStepStepProps<
  T extends BookingWizardFinalSeatsStepFormValues
> {
  formik: FormikContextType<T>
  bookingForm?: BookingFormValues
  customerCode?: string
}
export const BookingWizardSharedFinalizeStep = <T extends BookingWizardFinalSeatsStepFormValues>({
  formik,
  bookingForm,
  customerCode,
}: BookingWizardSharedFinalizeStepStepProps<T>) => {
  const [productsSeats, setProductsSeats] = useState<TicketModelFulfillBulkParams[]>([])
  const [activeProduct, setActiveProduct] = useState<CustomersProductProps | null>(null)
  const {getModalState, open: openModal, hide: hideModal, isOpen} = useModalState()
  const {pushError} = useAlerts()
  const [isOpenn, setIsOpen] = useState<boolean>(false)
  const [selectedLocation, setSelectedLocation] = useState<string>('')
  const {isKeyLoading} = useLoadingState()
  useOnChange(bookingForm, () => {
    if (bookingForm) {
      formik.setFieldValue('customer', bookingForm.customer)
      formik.setFieldValue('products', bookingForm.products)
      formik.setFieldValue('vouchers', bookingForm.vouchers)
      formik.setFieldValue('eventCode', bookingForm.eventCode)
      formik.setFieldValue('customersSeats', bookingForm.customersSeats)
    }
  })

  const selectedSeats = useMemo(() => {
    if (formik.values.customersSeats && formik.values.customersSeats.length && activeProduct) {
      const found = formik.values.customersSeats.find(
        (item) => item.productCode === activeProduct.productCode
      )
      if (found) return found
    }
    return undefined
  }, [activeProduct, formik.values.customersSeats])

  const otherSeats = useMemo(() => {
    if (formik.values.customersSeats && formik.values.customersSeats.length && activeProduct) {
      const found = formik.values.customersSeats.filter((item) => {
        return (
          item.productCode !== activeProduct.productCode &&
          item.locationCode === selectedLocation &&
          (dateIsBetween(
            DateUtil.getDateFromApiString(activeProduct.startedAt),
            DateUtil.getDateFromApiString(item.startedAt),
            DateUtil.getDateFromApiString(item.endedAt)
          ) ||
            dateIsBetween(
              DateUtil.getDateFromApiString(activeProduct.endedAt),
              DateUtil.getDateFromApiString(item.startedAt),
              DateUtil.getDateFromApiString(item.endedAt)
            ))
        )
      })

      if (found) return found
    }
    return undefined
  }, [activeProduct, formik.values.customersSeats, selectedLocation])

  const columns = useMemo(() => {
    const columns: TableColumnOptions<CustomersProductProps>[] = [
      {
        field: 'code',
        label: 'Product Code',
        sortable: true,
        cellStyle: ColumnStyle.NAME,
        dataExtract: (data) => data.productCode,
      },
      {
        field: 'productName',
        label: 'Product Name',
        sortable: true,
        cellStyle: ColumnStyle.NAME,
        dataExtract: (data) => data.productName,
      },

      {
        field: 'productQty',
        label: 'Qty',
        sortable: true,

        cellStyle: ColumnStyle.NAME,
        dataExtract: (data) => data.productQty,
      },

      {
        field: 'productType',
        label: 'Type',
        sortable: true,
        cellStyle: ColumnStyle.NAME,
        render: ({data}) => (
          <Badge
            className='text-nowrap'
            uppercase
            variant={data.productType === 'product' ? 'info' : 'warning'}
          >
            {data.productType}
          </Badge>
        ),
      },
      {
        field: 'productIsSeated',
        label: 'Seated?',
        sortable: true,

        cellStyle: ColumnStyle.NAME,
        render: ({data}) =>
          data.productIsSeated ? (
            <MetronicIcon
              className='svg-icon-success svg-icon-1hx'
              iconType='Navigation'
              iconName='Check'
            />
          ) : null,
      },
      {
        field: 'customerSeats',
        label: 'Seats',
        sortable: true,

        render: ({data}) => {
          return <SeatTableColumn customersSeats={formik.values.customersSeats} data={data} />
        },
      },
    ]
    return columns
  }, [formik.values.customersSeats])

  const customersProducts = useMemo<CustomersProductProps[] | null>(() => {
    if (
      (formik.values.products && formik.values.products.length > 0) ||
      (formik.values.vouchers && formik.values.vouchers.length > 0)
    ) {
      const data: CustomersProductProps[] = concat(
        formik.values.products,
        formik.values.vouchers as any
      ).map((productVoucher) => {
        return {
          code: productVoucher?.data?.code || '',
          name: formik.values.customer?.name || '',
          productCode: productVoucher?.data?.code || '',
          productName: productVoucher?.data?.name || '',
          productType: productVoucher?.type || 'product',
          productQty: productVoucher?.count || 0,
          productIsSeated: productVoucher?.data?.isSeated || false,
          startedAt: productVoucher?.startDate
            ? productVoucher?.startDate
            : productVoucher?.data?.productCategory?.startedAt,
          endedAt: productVoucher?.endDate
            ? productVoucher?.endDate
            : productVoucher?.data?.productCategory?.endedAt,
          isTimeslot: productVoucher?.data?.isTimeslot,
        }
      })

      if (data) return data
      return null
    }
    return null
  }, [formik.values.customer?.name, formik.values.products, formik.values.vouchers])

  const {searchableLocalTableData, filterSearchableLocalTableData} = useLocalTableSearch({
    data: customersProducts,
    columns: customersProducts
      ? (map(columns, 'field') as Array<keyof CustomersProductProps>) // ['code' , 'name' , 'productCode' , 'productName' , 'productIsSeated' , 'productQty' , 'productType']
      : null,
    additionColumns: [
      'code',
      'name',
      'productCode',
      'childrenCodes',
      'startedAt',
      'endedAt',
      'isTimeslot',
      'productIsSeated',
    ],
  })

  const updateProductIfTimeslot = useCallback(
    (date: DateRange) => {
      try {
        const startDate = DateUtil.convertDateToApiString(date?.getStartOrFail())
        const endDate = DateUtil.convertDateToApiString(date?.getEndOrFail())
        if (activeProduct && startDate && endDate) {
          let index = -1
          let newProductsValues = [...formik.values.products]
          const found = newProductsValues.find((item, idx) => {
            if (item.data?.code === activeProduct.code) {
              index = idx
              return item
            }
            return null
          })
          if (found) {
            newProductsValues[index] = {
              ...found,
              startDate: startDate,
              endDate: endDate,
            }
            formik.setFieldValue('products', newProductsValues)
          }
        }
      } catch (e) {
        pushError(e)
      }
    },
    [pushError, activeProduct]
  )

  const handleSeatsSelection = useCallback(
    async (values: SeatMapSelectionModalFormValues) => {
      if (activeProduct && activeProduct.productCode) {
        if (activeProduct.isTimeslot && values.dateRange) {
          try {
            if (values.dateRange.getStartOrFail() && values.dateRange.getEndOrFail()) {
              updateProductIfTimeslot(values.dateRange)
            }
          } catch (e) {
            pushError(e)
            return
          }
        }
        const payload = getBulkSeatMapPayload(
          values,
          activeProduct.productCode,
          activeProduct.code,
          activeProduct.startedAt,
          activeProduct.endedAt
        )
        if (payload) {
          let newSeates = [...productsSeats]
          newSeates = newSeates.filter((item) => item.productCode !== payload.productCode)
          newSeates.push(payload)
          setProductsSeats(newSeates)
          setSelectedLocation('')
          hideModal()
        }
      }
    },
    [activeProduct, hideModal, productsSeats]
  )

  const handleNonseatedDates = useCallback(
    async (dates: DateRange[]) => {
      let index = -1
      if (activeProduct && activeProduct.productCode) {
        let newProductsValues = [...formik.values.products]
        const found = newProductsValues.find((item, idx) => {
          if (item.data?.code === activeProduct.code) {
            index = idx
            return item
          }
          return null
        })
        if (found && dates.length) {
          let timeSlotArray = []
          for (const d of dates) {
            timeSlotArray.push({
              startedAt: DateUtil.convertDateToApiString(d?.getStartOrFail()),
              endedAt: DateUtil.convertDateToApiString(d?.getEndOrFail()),
            })
          }
          newProductsValues[index] = {
            ...found,
            timeslots: timeSlotArray,
          }
          formik.setFieldValue('products', newProductsValues)
        }
      }
      setIsOpen(false)
    },
    [formik.values.products, activeProduct]
  )

  const handleCloseModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  useOnChange(productsSeats, () => {
    formik.setFieldValue('customersSeats', productsSeats)
  })

  const getLocationCodeCallBack = useCallback((location: string) => {
    setSelectedLocation(location)
  }, [])

  useEffect(() => {
    if (isOpen) {
      setSelectedLocation(selectedSeats?.locationCode || '')
    }
  }, [isOpen, selectedSeats?.locationCode])

  const getSeatAssignmentHandler = useCallback(
    async (data: CustomersProductProps) => {
      setActiveProduct(data)
      openModal()
    },
    [openModal]
  )
  const onFulfillNonSeatedProduct = useCallback(async (data: CustomersProductProps) => {
    setActiveProduct(data)
    setIsOpen(true)
  }, [])

  const minDate = useMemo(() => {
    const today = moment().startOf('day')
    if (activeProduct) {
      const startedAt = DateUtil.getDateFromApiString(activeProduct.startedAt)
      return moment.max(moment(startedAt), today).toDate()
    }
  }, [activeProduct])

  const maxDate = useMemo(() => {
    if (activeProduct) {
      return DateUtil.getDateFromApiString(activeProduct.endedAt)
    }
  }, [activeProduct])

  const initialValueDateRange = useMemo(() => {
    if (activeProduct && selectedSeats?.seats) {
      return new DateRange(
        DateUtil.getDateFromApiString(activeProduct.startedAt),
        DateUtil.getDateFromApiString(activeProduct.endedAt)
      )
    }
    return new DateRange()
  }, [activeProduct, selectedSeats])

  const initialValueDateRangeArray = useMemo(() => {
    if (activeProduct) {
      let index = -1
      let timeSlots = []
      let newProductsValues = [...formik.values.products]
      const found = newProductsValues.find((item, idx) => {
        if (item.data?.code === activeProduct.code) {
          index = idx
          return item
        }
        return null
      })

      if (found && found.timeslots?.length) {
        for (const d of found.timeslots) {
          timeSlots.push({
            startedAt: d.startedAt,
            endedAt: d.endedAt,
          })
        }
        return timeSlots
      }
    }
    return []
  }, [activeProduct, formik])

  const rowActions = useCallback(
    (data: CustomersProductProps) => (
      <CustomersProductTableActions
        data={data}
        onAssignSeats={getSeatAssignmentHandler}
        loading={isKeyLoading(data.code)}
        onFulfillNonSeatedProduct={onFulfillNonSeatedProduct}
      />
    ),
    [getSeatAssignmentHandler, isKeyLoading]
  )

  return (
    <div className='container'>
      <div>
        {formik.values.customer && <FinalStepCustomerHeader customer={formik.values.customer} />}
        <FilterTable
          onFilter={filterSearchableLocalTableData}
          idExtractor={idExtractor}
          data={searchableLocalTableData.data}
          currentPageNumber={searchableLocalTableData?.page}
          columns={columns}
          totalItems={searchableLocalTableData?.total}
          actions={rowActions}
        />

        <BookingSeatMap
          onSubmit={handleSeatsSelection}
          product={activeProduct}
          eventCode={formik.values.eventCode}
          selectedSeat={selectedSeats}
          otherSeats={otherSeats}
          customerCode={customerCode}
          getLocationCodeCallBack={getLocationCodeCallBack}
          initialValues={{
            locationCode: selectedSeats?.locationCode || '',
            selected: selectedSeats?.seats || new SeatMapValue(),
            dateRange: initialValueDateRange,
          }}
          {...getModalState()}
        />

        <NonSeatedTimeslotDateModalForm
          minDate={minDate}
          maxDate={maxDate}
          qty={activeProduct ? activeProduct.productQty : 0}
          onSubmit={handleNonseatedDates}
          open={isOpenn}
          onHide={handleCloseModal}
          initialDates={initialValueDateRangeArray}
        />
      </div>
    </div>
  )
}

export const dateIsBetween = (date: Date, start: Date, end: Date) => {
  return moment(date).isBetween(start, end, undefined, '[]')
}
