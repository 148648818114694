import {FormikContextType} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import {ProductModel} from '../../../../../models/ems/ProductModel'
import {useRouteMatch} from 'react-router-dom'
import {GetProductByCode} from '../../redux/RetailBookingCrud'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {BookingWizardFormValues} from '../BookingWizard'
import moment from 'moment'

export interface BookingWizardPaymentInformationValues {
  amount: number
  products: Array<{
    code: string
    qty: number
  }>
  isSeated: boolean
  isTimeslot: boolean
}

export interface BookingWizardPaymentInformationProps<T extends BookingWizardFormValues> {
  formik: FormikContextType<T>
  onSubmit: () => void
}

interface RouteMatch {
  productCode: string
}

export const BookingWizardPaymentInformation = <T extends BookingWizardFormValues>({
  formik,
  onSubmit,
}: BookingWizardPaymentInformationProps<T>) => {
  const match = useRouteMatch<RouteMatch>()
  const {productCode} = match.params
  const [product, setProduct] = useState<ProductModel>()
  const [quantity, setQuantity] = useState(1)
  const {pushError} = useAlerts()

  const fetchProduct = useCallback(
    async (code: string) => {
      try {
        const productData = await GetProductByCode(code)
        setProduct(productData.data)
        formik.setFieldValue('products', [productData.data])
        formik.setFieldValue('isSeated', productData.data.isSeated)
        formik.setFieldValue('isTimeslot', productData.data.isTimeslot)
      } catch (e: any) {
        pushError(e)
      }
    },
    [formik, pushError]
  )

  useEffect(() => {
    if (productCode) {
      fetchProduct(productCode)
      formik.setFieldValue('productCode', productCode)
    }
  }, [productCode])

  useEffect(() => {
    if (product && product.price) {
      formik.setFieldValue('amount', product.price * quantity)
    }
  }, [product])

  const taxes = formik.values.amount * 0.05
  const total = formik.values.amount + taxes

  return (
    <>
      <div
        className='bg-body rounded shadow-sm p-10 p-lg-15'
        style={{maxWidth: '57vw', margin: 'auto'}}
      >
        {product && (
          <div className='booking-container' style={{fontFamily: 'Arial, sans-serif'}}>
            <div
              className='booking-summary'
              style={{backgroundColor: '#f4f4f4', padding: '20px', borderRadius: '4px'}}
            >
              <h4 style={{margin: '0 0 10px 0', color: 'black'}}>You're Booking:</h4>
              <p style={{margin: '0 0 10px 0', color: 'black'}}>{product.name}</p>
              {formik.values.bookingDate && (
                <div style={{margin: '0 0 10px 0', color: 'black'}}>
                  {moment(formik.values.bookingDate).format('dddd, MMMM D, YYYY [at] h:mm A')} –{' '}
                  {moment(formik.values.bookingDate).add(1, 'hours').format('h:mm A')}
                </div>
              )}
            </div>

            <div className='booking-pricing' style={{margin: '20px 0'}}>
              <div className='price-breakdown'>
                <p>Subtotal: AED {formik.values.amount.toFixed(2)}</p>
                <p>Taxes & Fees: AED {taxes.toFixed(2)}</p>
                <p>Total: AED {total.toFixed(2)}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export const STEP_PAYMENT_KEYS: Array<keyof BookingWizardPaymentInformationValues> = ['amount']
