import React, {createContext, useContext, useState, ReactNode} from 'react'

interface StepperStateContextType {
  registerSubmit: boolean
  setRegisterSubmit: (data: boolean) => void
  clickedRegister: boolean
  setClickedRegister: (data: boolean) => void
  loginSubmit: boolean
  setLoginSubmit: (data: boolean) => void
  resetSubmit: boolean
  setResetSubmit: (data: boolean) => void
  authToken: boolean
  setRetailAuthToken: (data: boolean) => void
  selectedRetailEvent: string
  setSelectedRetailEvent: (event: string) => void
  selectedRetailActivity: string
  setSelectedRetailActivity: (event: string) => void
  selectedRetailProduct: string
  setSelectedRetailProduct: (product: string) => void
}

const StepperStateContext = createContext<StepperStateContextType | undefined>(undefined)

export const StepperStateProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [registerSubmit, setRegisterSubmit] = useState(false)
  const [resetSubmit, setResetSubmit] = useState(false)
  const [clickedRegister, setClickedRegister] = useState(false)
  const [loginSubmit, setLoginSubmit] = useState(false)
  const [authToken, setRetailAuthToken] = useState(false)
  const [selectedRetailEvent, setSelectedRetailEvent] = useState('')
  const [selectedRetailActivity, setSelectedRetailActivity] = useState('')
  const [selectedRetailProduct, setSelectedRetailProduct] = useState('')

  return (
    <StepperStateContext.Provider
      value={{
        registerSubmit,
        setRegisterSubmit,
        setClickedRegister,
        clickedRegister,
        loginSubmit,
        setLoginSubmit,
        resetSubmit,
        setResetSubmit,
        selectedRetailEvent, 
        setSelectedRetailEvent, 
        selectedRetailActivity,
        setSelectedRetailActivity,
        selectedRetailProduct, 
        setSelectedRetailProduct,
        authToken,
        setRetailAuthToken,
      }}
    >
      {children}
    </StepperStateContext.Provider>
  )
}

export const useStepperState = () => {
  const context = useContext(StepperStateContext)
  if (!context) {
    throw new Error('useStepperState must be used within a StepperStateProvider')
  }
  return context
}