import {FormikContextType} from 'formik'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {ScheduleMeeting} from 'react-schedule-meeting'
import {DateUtil} from '../../../../../utils/DateUtil'
import {useRouteMatch} from 'react-router-dom'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {
  GetAvailableSeatMaps,
  GetLocationByCode,
  GetLocationsByProductCode,
  GetProductByCode,
} from '../../redux/RetailBookingCrud'
import {SeatMapValue} from '../../../../../components/inputs/SeatMapInput/SeatMapValue'
import {SeatMapSelectionInput} from '../../../../../components/inputs/SeatMapInput/SeatMapSelectionInput'
import {useSeatMapState} from '../../../../../components/hooks/useSeatMapState'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {TreeNodeItem} from '../../../../../components/inputs/TreeSelect/TreeSelect'
import {ApiTree} from '../../../../../utils/Tree/ApiTree'
import {LocationModel} from '../../../../../models/acs/LocationModel'
import {useStepperState} from '../../../pages/StepperStateContext'
import {ProductModel} from '../../../../../models/ems/ProductModel'

export interface BookingWizardCalendarInformationValues {
  bookingDate: Date | null
  seats: SeatMapValue | null
  locationCode?: string
}

export interface BookingWizardCalendarInformationProps<
  T extends BookingWizardCalendarInformationValues
> {
  formik: FormikContextType<T>
  onDateSelected: () => void
}

export interface StartTimeEventEmit {
  availableTimeslot: AvailableTimeslot
  startTime: Date
  splitTimeslot?: [SplitTimeslot, SplitTimeslot]
  resetDate: () => void
  resetSelectedTimeState: () => void
}
type AvailableTimeslot = {
  startTime: Date
  endTime: Date
  id?: string | number | undefined
}

type SplitTimeslot = null | ModifiedTimeslot

type ModifiedTimeslot = AvailableTimeslot & {
  oldId: string | number | undefined
}
interface RouteMatch {
  productCode: string
}

export const BookingWizardCalendarInformation = <T extends BookingWizardCalendarInformationValues>({
  formik,
  onDateSelected,
}: BookingWizardCalendarInformationProps<T>) => {
  const [selectedLocation, setSelectedLocation] = useState<string>('')
  const match = useRouteMatch<RouteMatch>()
  const {productCode} = match.params
  const [product, setProduct] = useState<ProductModel>()
  const {pushError, push} = useAlerts()
  const {selectedRetailEvent} = useStepperState()
  const [startTime, setStartTime] = useState<string>('')
  const [endTime, setEndTime] = useState<string>('')
  const [locations, setLocations] = useState<LocationModel[]>([])
  const [selected, setSelected] = useState<SeatMapValue>()
  const seatMapStyle = {
    height: '450px',
  }

  const {
    resetState,
    columns,
    rows,
    occupied,
    extra,
    disabled,
    hidden,
    isRightToLeft,
    isBottomToTop,
    setAvailableSeates,
    seatMapSpacingX,
  } = useSeatMapState()

  const locationItems = useMemo((): TreeNodeItem[] => {
    const locationTree = new ApiTree(locations)
    return locationTree.getTreeSelectItems()
  }, [locations])

  const fetchProduct = useCallback(
    async (code: string) => {
      try {
        const productData = await GetProductByCode(code)
        setProduct(productData.data)
      } catch (e: any) {
        pushError(e)
      }
    },
    [pushError]
  )

  const resetLocationList = useCallback(async () => {
    setLocations([])
    if (productCode) {
      try {
        const {data} = await GetLocationsByProductCode(productCode)
        if (data) setLocations(data)
      } catch (err: any) {
        pushError(err)
      }
    }
  }, [productCode, pushError])

  const inputValues = useMemo(() => {
    return {selectedLocation, startTime, endTime}
  }, [endTime, selectedLocation, startTime])

  //Do not update dependencies
  useEffect(() => {
    if (productCode) {
      fetchProduct(productCode)
      formik.setFieldValue('productCode', productCode)
    }
  }, [productCode])

  useOnChange(inputValues, async () => {
    resetState()

    if (selectedLocation && selectedRetailEvent && productCode) {
      const {data: location} = await GetLocationByCode(selectedLocation)
      if (location.seatMap) {
        resetState(location.seatMap)
      } else {
        push({message: `${location.name} has no seats.`, variant: 'danger', timeout: 5000})
      }

      try {
        const {
          data: {availableSeats},
        } = await GetAvailableSeatMaps(
          selectedLocation,
          productCode,
          selectedRetailEvent,
          startTime,
          endTime
        )
        setAvailableSeates(new SeatMapValue(availableSeats))
      } catch (e) {
        // No seats are occupied yet.
      }
    }
  })

  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  let startDate = product && product.productCategory
    ? new Date(product.productCategory.startedAt)
    : new Date()

  let endDate = product && product.productCategory
    ? new Date(product.productCategory.endedAt)
    : new Date()

  if (startDate < today) {
    startDate = new Date(today)
  }

  if (endDate < startDate) {
    endDate = new Date(startDate)
  }

  const totalDays = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24))

  const availableTimeslots = Array.from({ length: totalDays }, (_, id) => {
    const startTime = new Date(startDate.getTime())
    startTime.setDate(startTime.getDate() + id)
    startTime.setHours(9, 0, 0, 0) 

    const endTime = new Date(startTime)
    endTime.setHours(20, 0, 0, 0)

    return {
      id,
      startTime,
      endTime,
    }
  })

  const handleTimeslotClicked = (data: any) => {
    let endedAt: any
    if (data.startTime) {
      setStartTime(DateUtil.convertDateToApiString(data.startTime))
      let date = new Date(data.startTime)
      date.setHours(date.getHours() + 1)
      endedAt = date.toISOString()
      setEndTime(endedAt)
    }

    formik.setFieldValue('bookingDate', DateUtil.convertDateToApiString(data.startTime))
  }

  useOnChange(productCode, () => {
    if (productCode) {
      resetLocationList()
    }
  })

  const handleOnChange = useCallback(
    (values: SeatMapValue) => {
      const remaining = 1 - values.getValueCount()
      if (remaining >= 0) {
        formik.setFieldValue('seats', values.getSeatMapObject())
        formik.setFieldValue('locationCode', selectedLocation)
        setSelected(values)
      }
    },
    [formik, selectedLocation]
  )

  return (
    <>
      <div className='d-flex flex-column overflow-hidden'>
        {product?.isTimeslot && (
          <ScheduleMeeting
            borderRadius={10}
            primaryColor='#3f5b85'
            eventDurationInMinutes={60}
            availableTimeslots={availableTimeslots}
            format_selectedDateDayTitleFormatString='LLLL do'
            startTimeListStyle='scroll-list'
            onStartTimeSelect={handleTimeslotClicked}
            lang_confirmButtonText='Confirm this time? '
            skipConfirmCheck
            // lang_selectedButtonText=''
          />
        )}
        {productCode && product?.isSeated && product.isTimeslot && startTime && (
          <div style={{marginTop: '1rem'}}>
            <p style={{fontWeight: 'bold'}}>Choose a seat:</p>
            <SeatMapSelectionInput
              locationItems={locationItems}
              locationCode={selectedLocation}
              onLocationChange={setSelectedLocation}
              spacingX={seatMapSpacingX}
              columns={columns}
              rows={rows}
              selected={selected}
              occupied={occupied}
              onSelectedChange={handleOnChange}
              maxSelected={1}
              extra={extra}
              disabled={disabled}
              hidden={hidden}
              isRightToLeft={isRightToLeft}
              isBottomToTop={isBottomToTop}
              disabledIcon
              style={seatMapStyle}
            />
          </div>
        )}
        {productCode && product?.isSeated && !product.isTimeslot && (
          <div style={{marginTop: '1rem'}}>
            <p style={{fontWeight: 'bold'}}>Choose a seat:</p>
            <SeatMapSelectionInput
              locationItems={locationItems}
              locationCode={selectedLocation}
              onLocationChange={setSelectedLocation}
              spacingX={seatMapSpacingX}
              columns={columns}
              rows={rows}
              selected={selected}
              occupied={occupied}
              onSelectedChange={handleOnChange}
              maxSelected={1}
              extra={extra}
              disabled={disabled}
              hidden={hidden}
              isRightToLeft={isRightToLeft}
              isBottomToTop={isBottomToTop}
              disabledIcon
              style={seatMapStyle}
            />
          </div>
        )}
      </div>
    </>
  )
}

export const STEP_CALENDAR_KEYS: Array<keyof BookingWizardCalendarInformationValues> = [
  'bookingDate',
]
