import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {ProductCard} from '../components/product-card/ProductCard'
import clsx from 'clsx'
import style from './RetailProductsStyle.module.scss'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {FilterModel} from '../../../models/FilterModel'
import {ProductModel} from '../../../models/ems/ProductModel'
import {GetProductList} from '../components/redux/RetailBookingCrud'
import {GetAppConfig} from '../../app-config/redux/AppConfigCRUD'
import {AppConfigModel} from '../../../models/app-config/AppConfigModel'
import {useStepperState} from './StepperStateContext'
import {MetronicIconButton} from '../../../components/inputs/MetronicIconButton'

export const RetailProductsPage = () => {
  const history = useHistory()
  const {pushError} = useAlerts()
  const {activityCode, eventCode} = useParams<{activityCode?: string, eventCode?: string}>()
  const [products, setProducts] = useState<GlobalSearchModel<ProductModel>>()
  const [appconfigContent, setAppconfigContent] = useState<AppConfigModel[]>()
  const {selectedRetailEvent, setSelectedRetailProduct} = useStepperState()

  const handleBookNowClick = (code: string) => {
    history.push(`/auth/book/${code}/${selectedRetailEvent}`)
    setSelectedRetailProduct(code)
  }
  const handleBackClick = () => {
    history.push(`/auth/activity/${eventCode}`)
  }

  const getProducts = useCallback(
    async (filters?: FilterModel) => {
      try {
        const {data} = await GetProductList({
          ...filters,
          filters: {
            event: activityCode,
          },
        })
        setProducts(data)
      } catch (e: any) {
        pushError(e)
      }
    },
    [activityCode, pushError]
  )
  const GetConfigGroup = useCallback(async () => {
    try {
      const {data} = await GetAppConfig()
      setAppconfigContent(data)
    } catch (e: any) {
      pushError(e)
    }
  }, [pushError])

  useEffect(() => {
      getProducts()
      GetConfigGroup()
  }, [GetConfigGroup, getProducts])

  const appConfig = useMemo(() => {
    if (appconfigContent) {
      const siteObj = appconfigContent?.filter((item) => item.code === 'SITEURL')
      return siteObj[0]?.data
    }
  }, [appconfigContent])

  return (
    <>
      <div className={clsx('d-flex', style.retailPageHeader)}>
        <div onClick={() => handleBackClick()}>
          <MetronicIconButton
            variant='text'
            iconType='Navigation'
            iconName='Angle-left'
            size='sm'
            tooltip='Go back'
          />
          BACK
        </div>

        <h2 className='mt-2' style={{fontSize: '22.75px'}}>PRODUCT LIST</h2>

        <div style={{width: 48}}></div>
      </div>
      <div className={style.cardContainer}>
        {products &&
          appConfig &&
          products.data.map((product) => (
            <ProductCard
              key={product.code}
              product={product}
              onBookNow={() => handleBookNowClick(product.code)}
              config={appConfig}
            />
          ))}
      </div>
    </>
  )
}
