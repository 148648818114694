import {useCallback, useEffect, useMemo, useState} from 'react'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {useFormik} from 'formik'
import {CreateRetailBooking, GetProductByCode} from '../redux/RetailBookingCrud'
import {Button} from '../../../../components/inputs/Button'
import {pick} from 'lodash'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import * as yup from 'yup'
import {
  BookingWizardCalendarInformation,
  BookingWizardCalendarInformationValues,
  STEP_CALENDAR_KEYS,
} from './steps/BookingWizardCalendarInformation'
import {BookingWizardCustomerInformation} from './steps/BookingWizardCustomerInformation'
import {
  BookingWizardPaymentInformation,
  BookingWizardPaymentInformationValues,
  STEP_PAYMENT_KEYS,
} from './steps/BookingWizardPaymentInformation'
import clsx from 'clsx'
import style from '../../pages/RetailProductsStyle.module.scss'
import {useHistory, useParams} from 'react-router-dom'
import {useCustomerAuth} from '../../../customer-portal/hooks/useCustomerAuth'
import {BookingWizardRegistrationInformation} from './steps/BookingWizardRegistrationInformation'
import {useStepperState} from '../../pages/StepperStateContext'
import {BookingWizardResetInitialInformation} from './steps/BookingWizardResetInitialInformation'
import {SeatMapValue} from '../../../../components/inputs/SeatMapInput/SeatMapValue'
import {ProductModel} from '../../../../models/ems/ProductModel'

export enum FormStep {
  CALENDAR = 'CALENDAR',
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  PAYMENT = 'PAYMENT',
  FIRSTLOGIN = 'FIRSTLOGIN',
}

const StepOrder = [
  FormStep.CALENDAR,
  FormStep.LOGIN,
  FormStep.SIGNUP,
  FormStep.FIRSTLOGIN,
  FormStep.PAYMENT,
]

export interface BookingWizardFormValues
  extends BookingWizardCalendarInformationValues,
    // BookingWizardCustomerInformationValues,
    BookingWizardPaymentInformationValues {}

export const BookingWizard = () => {
  const {
    setRegisterSubmit,
    clickedRegister,
    setClickedRegister,
    setLoginSubmit,
    setResetSubmit,
    selectedRetailActivity,
    selectedRetailProduct,
    setRetailAuthToken,
  } = useStepperState()
  const [currentStep, setCurrentStep] = useState(FormStep.CALENDAR)
  const history = useHistory()
  const {eventCode} = useParams<{ eventCode?: string}>()
  const {pushError} = useAlerts()
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [loginPrevious, setLoginPrevious] = useState(false)
  const [registerPrevious, setRegisterPrevious] = useState(false)
  const [resetPrevious, setResetPrevious] = useState(false)
  const [product, setProduct] = useState<ProductModel>()
  const auth = useCustomerAuth()
  const customerCode = useMemo(() => {
    return auth?.getUser().code
  }, [auth])

  const fetchProduct = useCallback(
    async (code: string) => {
      try {
        const productData = await GetProductByCode(code)
        setProduct(productData.data)
      } catch (e: any) {
        pushError(e)
      }
    },
    [pushError]
  )

  useEffect(() => {
    if (selectedRetailProduct) {
      fetchProduct(selectedRetailProduct)
    }
  }, [fetchProduct, selectedRetailProduct])

  //Non seated and non timeslot
  useEffect(() => {
    if (product && !product.isTimeslot && !product.isSeated) {
      if (customerCode) {
        setCurrentStep(FormStep.PAYMENT)
      } else {
        setCurrentStep(FormStep.LOGIN)
      }
    }
  }, [customerCode, product])

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: async (values) => {
      if (selectedRetailActivity) {
        try {
          const payload = getPayload(values, selectedRetailActivity)
          await CreateRetailBooking(payload)
          setHasSubmitted(true)
        } catch (e: any) {
          pushError(e)
        }
      }
    },
    validationSchema,
    validateOnMount: true,
  })

  useOnChange(clickedRegister, () => {
    if (clickedRegister) {
      setCurrentStep(FormStep.SIGNUP)
    }
  })

  const stepForm = useMemo(() => {
    switch (currentStep) {
      case FormStep.CALENDAR: {
        return (
          <BookingWizardCalendarInformation
            formik={formik}
            onDateSelected={() => setCurrentStep(FormStep.LOGIN)}
          />
        )
      }
      case FormStep.LOGIN: {
        return (
          <BookingWizardCustomerInformation
            clickedPrevious={loginPrevious}
            setLoginPrevious={setLoginPrevious}
            setCurrentStep={setCurrentStep}
          />
        )
      }
      case FormStep.SIGNUP: {
        return (
          <BookingWizardRegistrationInformation
            clickedPrevious={registerPrevious}
            setRegisterPrevious={setRegisterPrevious}
            setCurrentStep={setCurrentStep}
          />
        )
      }
      case FormStep.FIRSTLOGIN: {
        return (
          <BookingWizardResetInitialInformation
            setCurrentStep={setCurrentStep}
            clickedPrevious={resetPrevious}
            setPrevious={setResetPrevious}
          />
        )
      }
      case FormStep.PAYMENT: {
        return <BookingWizardPaymentInformation formik={formik} onSubmit={formik.handleSubmit} />
      }
    }
  }, [currentStep, formik, loginPrevious, registerPrevious, resetPrevious])

  const handleNextClick = useCallback(() => {
    switch (currentStep) {
      case FormStep.LOGIN:
        if (clickedRegister) {
          setClickedRegister(false)
          if (auth?.isFirstLogin()) {
            setCurrentStep(FormStep.FIRSTLOGIN)
          } else {
            setCurrentStep(FormStep.SIGNUP)
          }
        } else {
          setLoginSubmit(true)
        }
        break

      case FormStep.SIGNUP:
        setRegisterSubmit(true)
        break

      case FormStep.FIRSTLOGIN:
        setResetSubmit(true)
        break

      case FormStep.CALENDAR:
        if (customerCode) {
          setCurrentStep(FormStep.PAYMENT)
        } else {
          setRegisterSubmit(false)
          setCurrentStep(FormStep.LOGIN)
        }
        break

      default:
        setCurrentStep((prevStep) => {
          return StepOrder[StepOrder.indexOf(prevStep) + 1]
        })
    }
  }, [
    auth,
    clickedRegister,
    currentStep,
    customerCode,
    setClickedRegister,
    setLoginSubmit,
    setRegisterSubmit,
    setResetSubmit,
  ])

  const handleFormKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        handleNextClick()
      }
    },
    [handleNextClick]
  )
  const handlePreviousClick = useCallback(() => {
    switch (currentStep) {
      case FormStep.LOGIN:
        if (!customerCode) {
          if (!product?.isSeated && !product?.isTimeslot) {
            history.push(`/auth/retail/${selectedRetailActivity}/${eventCode}`)
          
          } else {
            setRetailAuthToken(false)
            setLoginPrevious(true)
            setCurrentStep(FormStep.LOGIN)
          }
        } else {
          history.push(`/auth/retail/${selectedRetailActivity}/${eventCode}`)
        }
        break
      case FormStep.CALENDAR:
        history.push(`/auth/retail/${selectedRetailActivity}/${eventCode}`)
        break
      case FormStep.PAYMENT:
        if (
          currentStep === FormStep.PAYMENT &&
          !product?.isSeated &&
          !product?.isTimeslot &&
          customerCode
        ) {
          history.push(`/auth/retail/${selectedRetailActivity}/${eventCode}`)
        } else {
          setCurrentStep(FormStep.CALENDAR)
        }
        break
      case FormStep.SIGNUP:
        setCurrentStep(FormStep.LOGIN)
        break
      default:
        setCurrentStep((prevStep) => StepOrder[StepOrder.indexOf(prevStep) - 1])
    }
  }, [currentStep, customerCode, eventCode, history, product?.isSeated, product?.isTimeslot, selectedRetailActivity, setRetailAuthToken])

  const stepHasErrors = useCallback(
    (step: FormStep): boolean => {
      let errors = formik.errors
      switch (step) {
        case FormStep.CALENDAR: {
          errors = pick(formik.errors, STEP_CALENDAR_KEYS)
          break
        }
        case FormStep.LOGIN: {
          errors = pick(formik.errors)
          break
        }
        case FormStep.SIGNUP: {
          errors = pick(formik.errors)
          break
        }
        case FormStep.FIRSTLOGIN: {
          errors = pick(formik.errors)
          break
        }
        case FormStep.PAYMENT: {
          errors = pick(formik.errors, STEP_PAYMENT_KEYS)
          break
        }
      }
      return Object.values(errors).some((value) => Boolean(value))
    },
    [formik.errors]
  )

  const paymentHasValues = useMemo(() => {
    const values = pick(formik.values, STEP_PAYMENT_KEYS)
    return Object.values(values).some((value) => Boolean(value))
  }, [formik.values])

  const actions = useMemo(() => {
    const currentStepIndex = StepOrder.indexOf(currentStep)
    const hasNext = currentStepIndex < StepOrder.length - 1

    return (
      <div className='row mt-5 mb-3'>
        <div className='col col-xs-12 col-md'>
          <Button className='w-100' variant='info' type='button' onClick={handlePreviousClick}>
            Previous
          </Button>
        </div>
        {hasNext && (
          <div className='col col-xs-12'>
            <Button
              className='w-100'
              variant='primary'
              type='button'
              disabled={stepHasErrors(currentStep)}
              onKeyPress={handleFormKeyPress}
              onClick={handleNextClick}
            >
              Next
            </Button>
          </div>
        )}
        {!hasNext && (
          <div className='col-xs-12 col-md-6'>
            <Button
              className='w-100'
              variant='primary'
              type='submit'
              disabled={!paymentHasValues || !formik.isValid || formik.isSubmitting}
            >
              BOOK
            </Button>
          </div>
        )}
      </div>
    )
  }, [
    currentStep,
    handlePreviousClick,
    stepHasErrors,
    handleFormKeyPress,
    handleNextClick,
    paymentHasValues,
    formik.isValid,
    formik.isSubmitting,
  ])
  const formTitle = useMemo(() => {
    if (!hasSubmitted) {
      let message: string = ''
      switch (currentStep) {
        case FormStep.CALENDAR: {
          message = 'RETAIL BOOKING'
          break
        }
        case FormStep.LOGIN: {
          message = 'LOGIN / REGISTER'
          break
        }
        case FormStep.SIGNUP: {
          message = 'REGISTER'
          break
        }
        case FormStep.FIRSTLOGIN: {
          message = 'Please Reset your Password'
          break
        }
        case FormStep.PAYMENT: {
          return (
            <>
              <h1 className='text-dark mb-3 pt-15 pb-5'>RETAIL BOOKING</h1>
            </>
          )
        }
      }
      return <h1 className='text-dark mb-3 pt-15 pb-5'>{message}</h1>
    }
    return (
      <>
        <div
          className='bg-body rounded shadow-sm p-10 p-lg-15 mt-20'
          style={{maxWidth: '57vw', margin: 'auto'}}
        >
          <p className='text-gray-400 fw-bold fs-4'>Thank you for completing your booking!</p>
          <p className='text-gray-400 fw-bold fs-4'>
            You will receive a confirmation email shortly.
          </p>
        </div>
      </>
    )
  }, [currentStep, hasSubmitted])

  const debouncedValidate = useDebounce(500)

  useOnChange(formik.values, () => {
    debouncedValidate(() => {
      formik.validateForm()
    })
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='text-center mb-5'>{formTitle}</div>
      {!hasSubmitted && (
        <>
          <div
            className={clsx('d-flex flex-column align-items-center', style.retailWizardContainer)}
          >
            <div className='w-100 mb-3 h-100' style={{marginBottom: '1rem'}}>
              {stepForm}
            </div>
            <div className='w-100' style={{marginBottom: '1rem'}}>
              {actions}
            </div>
          </div>
        </>
      )}
    </form>
  )
}

export const getPayload = (
  data: BookingWizardFormValues,
  eventCode: string
): BookingModelCreateParams => {
  let endedAt: string | null = null
  if (data.bookingDate) {
    let date = new Date(data.bookingDate)
    date.setHours(date.getHours() + 1)
    endedAt = date.toISOString()
  }

  const payload: BookingModelCreateParams = {
    eventCode: eventCode,
    products: data.products.map((item) => {
      const product: any = {
        code: item.code,
        qty: item.qty,
      }

      if (data.isSeated) {
        product.locationCode = data.locationCode
        product.seats = data.seats
      }

      if (data.isSeated && data.isTimeslot) {
        product.startedAt = data.bookingDate
        product.endedAt = endedAt
      }

      if (!data.isSeated && data.isTimeslot) {
        product.timeslots = [
          {
            startedAt: data.bookingDate,
            endedAt: endedAt,
          },
        ]
      }

      return product
    }),
  }

  return payload
}

export const INITIAL_VALUES: BookingWizardFormValues = {
  products: [],
  //   quantity: 1,
  amount: 1,
  bookingDate: null,
  seats: null,
  isSeated: false,
  isTimeslot: false,
}
export const validationSchema = yup.object().shape({
  //   email: yup.string().required('Please enter an email').email('Please enter a valid email'),
  //   firstName: yup.string().required('Please enter first name'),
  //   lastName: yup.string().required('Please enter last name'),
  //   bookingDate: yup.date().required('Please select time and date.'),
})

export interface BookingModelCreateParams {
  eventCode: string
  products: ProductCreateParams[]
  startedAt?: Date | null
  endedAt?: Date | null
  seats?: SeatMapValue | null
  locationCode?: string
}

interface ProductCreateParams {
  code: string
  qty: number
  timeslots: ProductTimeslots[]
}

interface ProductTimeslots {
  startedAt: Date | null
  endedAt: Date | null
}
