import {useHistory, useParams} from 'react-router-dom'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {ActivityModel} from '../../../models/ems/ActivityModel'
import {AppConfigModel} from '../../../models/app-config/AppConfigModel'
import {useStepperState} from './StepperStateContext'
import {FilterModel} from '../../../models/FilterModel'
import {GetAppConfig} from '../../app-config/redux/AppConfigCRUD'
import {GetActivityList} from '../components/redux/RetailBookingCrud'
import style from './RetailProductsStyle.module.scss'
import clsx from 'clsx'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'
import {Button} from '../../../components/inputs/Button'
import {ActivityCard} from '../components/activity-card/ActivityCard'
import {MetronicIconButton} from '../../../components/inputs/MetronicIconButton'

export const ActivityListPage = () => {
  const history = useHistory()
  const {pushError} = useAlerts()
  const {eventCode} = useParams<{eventCode?: string}>()
  const [activities, setActivities] = useState<GlobalSearchModel<ActivityModel>>()
  const [appconfigContent, setAppconfigContent] = useState<AppConfigModel[]>()
  const {setSelectedRetailActivity} = useStepperState()

  const handleClick = (code: string) => {
    history.push(`/auth/retail/${code}/${eventCode}`)
    setSelectedRetailActivity(code)
  }

  const handleBackClick = () => {
    history.push(`/auth/events`)
  }

  const getActivities = useCallback(
    async (filters?: FilterModel) => {
      try {
        const {data} = await GetActivityList({
          ...filters,
          filters: {
            parent: eventCode,
          },
        })
        setActivities(data)
      } catch (e: any) {
        pushError(e)
      }
    },
    [pushError, eventCode]
  )

  const GetConfigGroup = useCallback(async () => {
    try {
      const {data} = await GetAppConfig()
      setAppconfigContent(data)
    } catch (e: any) {
      pushError(e)
    }
  }, [pushError])

  useEffect(() => {
      getActivities()
      GetConfigGroup()
  }, [GetConfigGroup, getActivities])

  const appConfig = useMemo(() => {
    if (appconfigContent) {
      const siteObj = appconfigContent?.filter((item) => item.code === 'SITEURL')
      return siteObj[0]?.data
    }
  }, [appconfigContent])

  return (
    <>
      <div className={clsx('d-flex', style.retailPageHeader)}>
        <div onClick={() => handleBackClick()}>
          <MetronicIconButton
            variant='text'
            iconType='Navigation'
            iconName='Angle-left'
            size='sm'
            tooltip='Go back'
          />
          BACK
        </div>

        <h2 className='mt-2' style={{fontSize: '22.75px'}}>
          ACTIVITY LIST
        </h2>

        <div style={{width: 48}}></div>
      </div>
      <div className={style.cardContainer}>
        {activities &&
          activities.data &&
          activities.data.map((activity) => (
            <ActivityCard
              key={activity.code}
              data={activity}
              onClick={() => handleClick(activity.code)}
              config={appConfig}
            />
          ))}
      </div>
      <div className={clsx('d-flex', 'justify-content-center', style.retailPageContainer)}>
        {activities && activities.total > 10 && activities.total !== activities.data.length && (
          <Button
            uppercase={false}
            variant='primary'
            onClick={() =>
              getActivities({
                page: activities.page ? activities.page + 1 : 2,
                limit: activities.limit + activities.limit,
              })
            }
          >
            <MetronicIcon iconType='Code' iconName='Loading' />
            Load More
          </Button>
        )}
      </div>
    </>
  )
}
